import React, { useReducer, useState, useEffect } from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import Logo from '../images/dinggo-logo.inline.svg';
import '../assets/styles/base/standard-page.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Login = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);

  const [errorMessage, setErrorMessage] = useState('');
  const [bookingRef, setBookingRef] = useState('');
  const [ref, setRef] = useState('');
  const [redirect, setRedirect] = useState('');
  const [loginDetails, setloginDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      username: '',
      password: ''
    }
  );

  const getQueryStringValue = (key) => {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  };

  useEffect(() => {
    const { search } = location;
    if (search !== '') {
      const errorMsg = getQueryStringValue('error').replace(/\+/g, ' ');
      const bookingRefString = getQueryStringValue('bookingRef');
      const refString = getQueryStringValue('ref');
	  const redirectString = getQueryStringValue('redirect');

      if (errorMsg) {
        setErrorMessage(errorMsg);
      }
      if (bookingRefString) {
        setBookingRef(bookingRefString);
      }
      if (refString) {
        setRef(refString);
      }
	  if (redirectString) {
		setRedirect(redirectString);
	  }
    }
  }, [])

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setloginDetails({ [name]: value });
  };

  const hasLoginDetails = () => {
    let valid = true;
    if (loginDetails.username.length === 0) {
      valid = false;
    }
    if (loginDetails.password.length === 0) {
      valid = false;
    }

    return valid;
  };

  return (
    <Layout hideHeaderFooter={false}>
      <section className="standard-page bg-light py-5 vh-100 d-flex align-items-center">
        <SEO
          title=''
          description=''
          canonical=''
          stucturedData=''
          ogTitle=''
          ogDescription=''
          ogImage=''
          ogType=''
          ogUrl=''
        />
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-6 mb-4">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              <div className="card shadow-sm">
                <form className="card-body" action={`${process.env.GATSBY_PORT}endpoints/login`} method="post">
                  {bookingRef && (
                    <input type="hidden" name="bookingRef" value={bookingRef} />
                  )}
                  {ref && (
                    <input type="hidden" name="ref" value={ref} />
                  )}
				  {redirect && (
					<input type="hidden" name="redirect" value={redirect} />
				  )}
                  <h3 className="text-center py-4 mt-0">Login to your account</h3>
                  <div className="form-group">
                    <label htmlFor="name">Username</label>
                    <input type="text" className="form-control" id="username" name="username" onChange={(e) => { handleInputChange(e); }} value={loginDetails.username} />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="name">Password</label>
                    <input type="password" className="form-control" id="password" name="password" onChange={(e) => { handleInputChange(e); }} value={loginDetails.password} />
                  </div>
                  {errorMessage.length > 0 && (
                    <p className="error">{errorMessage}</p>
                  )}
                  <button className={`btn btn-block btn-lg mb-4 ${hasLoginDetails() ? '' : 'disable'}`} type="submit">Login</button>
                  <div className="text-center mb-4">
                    <Link className="dark-link" to="/forgot-password">Forgot your password?</Link>
                  </div>
                  {data.general.country_code === 'nz' &&
                    <div>
                      <div className="text-center mb-4">
                        Crash Management Legacy System Login for claim lodged before November 2023
                      </div>
                      <div className="text-center mb-4">
                        <a href="https://app.proworkflow.com/Crashmanagement/" className="btn">Legacy Login</a>
                      </div>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Login;
